<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loading">
              <b-row>
                <b-col lg="12" sm="12">
                  <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                    <b-form @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset">
                      <b-row>
                            <b-col class="col-sm-12 col-md-12">
                            <ValidationProvider name="Crop Type" vid="type_id" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="3"
                                    label-for="type_id"
                                    slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                    {{$t('VirtualMuseum.configuration.crop_type')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                    plain
                                    v-model="cropData.type_id"
                                    :options="cropTypeList"
                                    id="type_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>

                        <b-col class="col-sm-12 col-md-12">
                          <ValidationProvider name="Crop Type Name (En)" vid="name_en" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="3"
                                label-for="name_en"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{ $t('VirtualMuseum.configuration.name_en') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                id="name_en"
                                plain
                                v-model="cropData.name_en"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                </b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col class="col-sm-12 col-md-12">
                          <ValidationProvider name="Crop Type Name (Bn)" vid="name_bn" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="3"
                                label-for="name_bn"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{ $t('VirtualMuseum.configuration.name_bn') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                id="name_bn"
                                plain
                                v-model="cropData.name_bn"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                </b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                       <b-col class="col-sm-12 col-md-12">
                            <ValidationProvider name="Description (En)" vid="description" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="3"
                                    label-for="name"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                    {{$t('pest_management.description_en')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-textarea
                                    id="museum_description"
                                    v-model="cropData.description"
                                    max="500"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    cols="40"
                                    rows="4"
                                    ></b-form-textarea>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col class="col-sm-12 col-md-12">
                              <ValidationProvider name="Description (Bn)" vid="description_bn" rules="required">
                                  <b-form-group
                                      class="row"
                                      label-cols-sm="3"
                                      label-for="name"
                                      slot-scope="{ valid, errors }"
                                  >
                                      <template v-slot:label>
                                      {{$t('pest_management.description_bn')}} <span class="text-danger">*</span>
                                      </template>
                                      <b-form-textarea
                                      id="museum_description_bn"
                                      v-model="cropData.description_bn"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      cols="40"
                                      rows="4"
                                      ></b-form-textarea>
                                      <div class="invalid-feedback">
                                          {{ errors[0] }}
                                      </div>
                                  </b-form-group>
                              </ValidationProvider>
                          </b-col>
                         <b-col class="col-sm-12 col-md-12">
                          <ValidationProvider name="Crop Image" vid="crop_image">
                            <b-form-group
                                class="row"
                                label-cols-sm="3"
                                label-for="crop_image"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{ $t('VirtualMuseum.configuration.image') }}
                              </template>
                              <b-form-file
                                  id="crop_image"
                                  ref="crop_image"
                                  v-on:change="onFileChange"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-file>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                              <div class="cust-feedback">
                                Please Upload png,gif,jpeg,jpg image
                              </div>
                              <a v-if="id && cropData.crop_image" class="btn btn-sm btn-success mt-2" target='_blank' :href="virtualMuseumServiceBaseUrl + 'uploads/crop/original/' + cropData.crop_image">
                                  <img class="list-image-layout"
                                       :src="virtualMuseumServiceBaseUrl + 'uploads/crop/original/' + cropData.crop_image"
                                       alt="Crop Type Image">
                              </a>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <!-- ==========Start============ -->
                        <b-col class="col-sm-12 col-md-12">
                            <ValidationProvider name="Image Source" vid="crop_image_src" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="2"
                                label-for="crop_image_src"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                {{$t('pest_management.image_source')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                id="crop_image_src"
                                v-model="cropData.crop_image_src"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                        </b-col>
                    <!-- ==========End============== -->
                      </b-row>
                      <div class="row mt-2">
                        <div class="col text-right">
                          <b-button type="submit" variant="primary">{{ saveBtnName}}</b-button>
                          &nbsp;
                          <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                        </div>
                      </div>
                    </b-form>
                  </ValidationObserver>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<style scope>
    .cust-feedback{
        width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
    }
</style>
<script>
// import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { virtualMuseumServiceBaseUrl } from '@/config/api_config'
import { cropStore, cropUpdate } from '../../api/routes'
export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getCropData()
      this.cropData = tmp
    }
  },
  mounted () {
  },
  data () {
    return {
      loading: false,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      virtualMuseumServiceBaseUrl: virtualMuseumServiceBaseUrl,
      cropData: {
        type_id: 0,
        name_en: '',
        name_bn: '',
        description: '',
        description_bn: '',
        crop_image: '',
        crop_image_src: ''
      },
      attachmentDemo: '',
      url: virtualMuseumServiceBaseUrl
      // attachmentDemo: []
    }
  },
  computed: {
  cropTypeList: function () {
            const cropTypeData = this.$store.state.VirtualMuseum.commonObj.cropTypeList.filter(item => item.status === 1)
            return cropTypeData.map(item => {
                if (this.$i18n.locale === 'bn') {
                    return { value: item.value, text: item.text_bn }
                } else {
                    return { value: item.value, text: item.text_en }
                }
            })
        }
  },
  watch: {
  },
  methods: {
    onFileChange (e) {
      this.attachmentDemo = e.target.files[0]
    },
    getCropData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async saveData () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }
      const config = {
                headers: { 'content-type': 'multipart/form-data' }
            }
      var formData = new FormData()
      Object.keys(this.cropData).map(key => {
          if (key === 'crop_image') {
          formData.append(key, this.attachmentDemo)
          } else {
          formData.append(key, this.cropData[key])
          }
      })
      if (this.id) {
        formData.append('_method', 'PUT')
        result = await RestApi.postData(virtualMuseumServiceBaseUrl, `${cropUpdate}/${this.id}`, formData, config)
      } else {
        result = await RestApi.postData(virtualMuseumServiceBaseUrl, cropStore, formData, config)
      }
      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)
      this.loading = false

      if (result.success) {
        this.$store.commit('VirtualMuseum/mutateVirtualMuseumCommonProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: this.$t('globalTrans.success'),
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    }
  }
}
</script>
